@tailwind base;
@tailwind components;
@tailwind utilities;

/* Remove up/down arrows in inputs for Webkit browsers like Chrome and Safari */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove up/down arrows in inputs for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@font-face {
  font-family: "SF Pro Text";
  src: url("./fonts/SF-Pro-Text-Bold.otf");
  font-weight: 800;
}

@font-face {
  font-family: "SF Pro Text";
  src: url("./fonts/SF-Pro-Text-Regular.otf");
  font-weight: 400;
}

@font-face {
  font-family: "SF Pro Text";
  src: url("./fonts/SF-Pro-Text-Semibold.otf");
  font-weight: 600;
}

body {
  margin: 0;
  font-family: "SF Pro Text", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
